import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const userStore = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 회원정보 가져오기
        info: ({ commit }, params) => {

            var token = VueCookies.get('reoil_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/' + params.userUqId,
                    { headers: { Authorization: header } }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            })
        },

        // 회원정보 수정
        edit: ({ commit }, params) => {

            var token = VueCookies.get('reoil_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User/UpdateInfo', params,
                    { headers: { Authorization: header } }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            })
        },

        // 인증번호 발송
        sendSms: ({ commit }, params) => {

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User/SendSMS?to=' + params.phone, {}
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            })
        },

        // 인증번호 확인
        checkSms: ({ commit }, params) => {

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/CheckSMS?to=' + params.phone + '&authnumber=' + params.authnumber
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            })
        },
        // 회원 정보 확인
        checkPhone: ({ commit }, params) => {
            var token = VueCookies.get('reoil_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/CheckUserInfo', {
                    params: {
                        userId: params.userId
                    },
                    headers: {
                        Authorization: header
                    }
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            })
        },
        // 비밀번호 재설정
        newPassword: ({ commit }, params) => {
            var token = VueCookies.get('reoil_accessToken');
            var header = "Bearer " + token;
          
            return new Promise((resolve, reject) => {
              axios.post(SERVER.URL + '/api/User/UpdatePassword', params, {
                headers: { 
                  Authorization: header
                } 
              }).then(res => {
                //console.log(res);
                resolve(res.data);
              })
              .catch(err => {
                console.log(err.message);
                reject(err.message);
              });
            })
          },
          
    }
}

export default userStore