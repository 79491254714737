import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const priceStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 오늘 시세 가져오기
        getTodayPrice: ({commit}, params) => { 

            var token = VueCookies.get('reoil_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Price/GetTodayPrice',
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default priceStore