import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import store from '../store';
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    //dashboard
    { 
        path: '/', 
        name: 'Home', 
        meta: {
            authRequired: true,
        },
        component: Home 
    },
     
    // 로그인
     {
        path: '/auth/login',
        name: 'login',
        component: () => import( '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    // 회원가입
     {
        path: '/auth/join',
        name: 'join',
        component: () => import( '../views/auth/join.vue'),
        meta: { layout: 'auth' },
    },
    // 회원가입 - 간편 비밀번호 설정
     {
        path: '/auth/easynumber',
        name: 'easynumber',
        component: () => import( '../views/auth/easynumber.vue'),
        meta: { layout: 'auth' },
    },
    // 회원가입 - 간편 비밀번호 한번더
     {
        path: '/auth/easynumberAgain',
        name: 'easynumberAgain',
        component: () => import( '../views/auth/easynumberAgain.vue'),
        meta: { layout: 'auth' },
    },
    // 회원가입 완료
     {
        path: '/auth/joinComplete',
        name: 'joinComplete',
        component: () => import( '../views/auth/joinComplete.vue'),
        meta: { layout: 'auth' },
    },
    // 비밀번호 찾기 
    {
        path: '/auth/password_recovery',
        name: 'password_recovery',
        component: () => import( '../views/auth/password_recovery.vue'),
        meta: { layout: 'auth' },
    },
    // // 비밀번호 변경 
    //  {
    //     path: '/auth/password-reset',
    //     name: 'password-reset',
    //     component: () => import( '../views/auth/password-reset.vue'),
    //     meta: { layout: 'auth' },
    // },
    //포인트 > 메인
    {
        path: '/point/main', 
        name: 'point',
        component: () => import('../views/point/main.vue'),
        meta: {
            authRequired: true,
            subPage: false,
        },
    },
    //포인트 > 적립내역
    {
        path: '/point/list', 
        name: 'list',
        component: () => import('../views/point/list.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
    //위치
    {
        path: '/location/index', 
        name: 'location',
        component: () => import('../views/location/index.vue'),
        meta: {
            authRequired: true,
            subPage: false,
        },
    },
    //마이페이지 > 메인
    {
        path: '/mypage/main', 
        name: 'mypage',
        component: () => import('../views/mypage/main.vue'),
        meta: {
            authRequired: true,
            subPage: false,
        },
    },

    //마이페이지 > 정보관리
    {
        path: '/mypage/settinginfo', 
        name: 'settinginfo',
        component: () => import('../views/mypage/settinginfo.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
    //마이페이지 > faq
    {
        path: '/mypage/faq', 
        name: 'faq',
        component: () => import('../views/mypage/faq.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
    //마이페이지 > 공지사항
    {
        path: '/mypage/notice', 
        name: 'notice',
        component: () => import('../views/mypage/notice.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
    //마이페이지 > 환경설정
    {
        path: '/mypage/setting', 
        name: 'setting',
        component: () => import('../views/mypage/setting.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
    //마이페이지 > 소개
    {
        path: '/mypage/introduction', 
        name: 'introduction',
        component: () => import('../views/mypage/introduction.vue'),
        meta: {
            authRequired: true,
            subPage: true,
        },
    },
   
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkActiveClass: "active",
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {

    var token = VueCookies.get('reoil_accessToken');
    var isVerified = false;

    //return next();

    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        var UserUqid = decoded.UserUqid;
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
            
        }
    }
    //store.commit('logoutUser');

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    if(to.meta.subPage == false){
        store.commit('setSubPage', false);
    }else if(to.meta.subPage == true){
        store.commit('setSubPage', true);
    }

    if(to.path.includes("auth")){
        return next();
    }else{
        if (store.state.user.loggedIn){
            return next();
        }else{
            if (token !== null){
                //accessToken이 있을 경우 진행
                var userInfo = {
                    "accessToken": VueCookies.get('reoil_accessToken'),
                    "refreshToken": VueCookies.get('reoil_refreshToken'),
                    "userUqId": UserUqid,
                };

                //console.log(userInfo);
        
                if(isVerified){

                    // accessToken이 유효할 경우
                    store.commit('setLoggedIn', true);
                    store.commit('resetUserInfo', userInfo);

                    return next();

                }else{

                    // accessToken이 만료되었을 경우 - refreshToken으로 재발급
                    store.dispatch('usersStore/refresh_token', userInfo)
                    .then((res) => {
                        //console.log(res);
                        if(res.success){
                            let decoded = VueJwtDecode.decode(res.accessToken);
                            //console.log(decoded);
        
                            var userInfo_update = {
                                "accessToken": res.accessToken,
                                "refreshToken": res.refreshToken,
                                "userUqId": decoded.UserUqid,
                            };
        
                            //console.log(userInfo);
        
                            store.commit('setLoggedIn', true);
                            store.commit('resetUserInfo', userInfo_update);
                            
                            return next();

                        }else{
                            //alert("다른 기기에서 로그인하였습니다. 다시 로그인 해주세요.");
                            next("/auth/login");
                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }
                
            }else{
                if(!isVerified && token !== null){
                    // accessToken이 종료되었을 경우 로그아웃 진행
                    store.commit('logoutUser')

                    var userInfo = {
                        "userUqId": UserUqid,
                    };

                    store.dispatch('usersStore/logout', userInfo)
                    .then((res) => {
                        //console.log(res);
                        if(res.success){
                        
                            store.commit('logoutUser');

                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }
                const authRequired = to.matched.some((route) => route.meta.authRequired)

                if(!authRequired){
                    return next();
                }else{
                    //alert('로그인이 필요한 페이지입니다!');
                    next("/auth/login");
                }
            }
        }
    }
    //next(true);
});

export default router;
