<!-- https://wwit.design/2022/02/07/laundrygo/ -->
 <!-- 로그인 메인 페이지 내 기준 localhost:8080 -->
 <template>
    <div class="" style="margin-top: 17px;">
        <div class="mainmypointBox mb-3">
            <div class="txtBox">
                <h6>내 포인트</h6>
                <span>{{ myFinalPoints_with_comma }}P</span>
            </div>
            <div class="btnBox">
                <router-link to="/point/list">적립내역</router-link>
            </div>
        </div>

        <div class="mb-3">
            <div class="mapArea innerBox">
                <!-- 지도영역 -->
                <h6>근처기계</h6>

                <div id="map" style="width: 100%; height: 300px"></div>
            </div>
        </div>

        <div class="mb-3">
            <div class="innerBox datalistBox">
                <h6 class="pb-2">오늘의 보상가격</h6>

                <div class="widget-content">
                    <ul class="list">
                        <li>
                            <div class="t-name">
                                {{ todayDate }}
                            </div>
                            <div class="">{{ todayPrice }}원/g</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { defineComponent } from 'vue'
    import '@/assets/sass/widgets/widgets.scss';

    import { useStore } from 'vuex';

    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: '홈' });

    const store = useStore();
    
</script>

<script>
import VueCookies from 'vue-cookies';

export default {
    data() {
        return {
            todayDate: "",
            todayPrice: 0,
            useruqid: "",
            myFinalPoints_with_comma: "",
            Lat: 0,
            Lon: 0,
            markerList: [],
        };
    },
    created() {

    },
    mounted(){
        
        var user = this.$store.getters['user'];
        this.useruqid = user.data.userUqId;
        
        var token = VueCookies.get('reoil_accessToken');
        if(token != "" && token != null && token != undefined){
            this.getMyPoint();
        }

        var today = new Date();
        var year = today.getFullYear();
        var month = ("0" + (1 + today.getMonth())).slice(-2);
        var day = ("0" + today.getDate()).slice(-2);

        this. todayDate = year + "년 " + month + "월 " + day + "일";
        
        var token = VueCookies.get('reoil_accessToken');
        if(token != "" && token != null && token != undefined){
            this.getTodayPrice();
            this.getList();
        }       

    },
    methods: {

        // 포인트 정보 가져오기
        getMyPoint(){

            var params = {
                "Useruqid": this.useruqid,
            };
            //console.log(params);

            this.$store.dispatch('pointStore/getMyPoint', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    this.myFinalPoints_with_comma = res.data.myFinalPoints_with_comma;
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 오늘의 보상가격 가져오기
        getTodayPrice(){
            var params = {};

            this.$store.dispatch('priceStore/getTodayPrice', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    this.todayPrice = res.data.price_with_comma;
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 장비 리스트 가져오기
        async getList(){

            this.markerList = [];

            var params = {
                "SchType": "",
                "SchText": "",
                "approvalStatus": "approve",
            };

            await this.$store.dispatch('deviceStore/getList', params)
            .then((res) => {
                //console.log(res);
                if (res.success) {

                    for(var i = 0; i < res.data.length; i++){
                        var device = res.data[i];
                        device.lng = res.data[i].lon;
                        this.markerList.push(device);
                    }
                   
                    this.getCurrentLocation();
                }
                return res;
            })
            .catch(({
                message
            }) => (alert(message)));
        },

        // 현재위치 가져오기
        async getCurrentLocation(){
            if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition((position) => {
                    //console.log(position);
                    this.Lat = position.coords.latitude;
                    this.Lon = position.coords.longitude;
                    /*
                    setMyLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    */

                    if (window.naver && window.naver.maps) {
                        this.loadMap();
                    } else {
                        this.loadScript();
                    }
                });
            } else {
                window.alert("현재위치를 알수 없습니다.");
                this.Lat = 37.529169;
                this.Lon = 127.136264;

                if (window.naver && window.naver.maps) {
                    this.loadMap();
                } else {
                    this.loadScript();
                }
            }
        },

        loadScript() {
            const script = document.createElement("script");
            script.onload = () => naver.maps.load(this.loadMap);
            script.src =
                "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=pketv0fmoq&submodules=geocoder";
            document.head.appendChild(script);
        },

        loadMap() {

            const container = document.getElementById("map");
            const mapOptions = {
                center: new naver.maps.LatLng(this.Lat, this.Lon), // 위도, 경도
                zoom: 13,
            };
            const map = new naver.maps.Map(container, mapOptions);

            
            //내 위치 마커 표시
            /*
            const currentMarker = new naver.maps.Marker({
            position: new naver.maps.LatLng(this.Lat, this.Lon),
            map,
            title: '현재 위치',
            // 아이콘 이미지 변경
            icon: {
                url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                scaledSize: new naver.maps.Size(32, 32),
            }
            
        });
        */
       
            // 주변 마커 나타내기
            const markers = [];
            const infowindows = [];
            
            // 반복문을 통해 찍어준다
            for (let i = 0; i < this.markerList.length; i += 1) {
                const otherMarkers = new naver.maps.Marker({
                    position: new naver.maps.LatLng(
                        this.markerList[i].lat,
                        this.markerList[i].lng
                    ),
                    map,
                });

                /*

                if(this.markerList[i].status == "S"){
                    var color = "green";
                    var status = "정상";
                }else if(this.markerList[i].status == "E"){
                    var color = "red";
                    var status = "고장";
                }else{
                    var color = "yellow";
                    var status = "수리중";
                }
                */

                var contentTags ='<div class="naver-container"><span class="tagBox">위치 : '+ this.markerList[i].deviceName;
               // var contentTags ='<div class="naver-container"><span class="tagBox">위치 : '+ this.markerList[i].deviceName +'<span><br><span class="tagBox">상태 : <span class="status '+color+'">'+ status+'</span></span></div>';


                const infowindow = new naver.maps.InfoWindow({
                    content: contentTags,
                    borderWidth: 1,
                    anchorSize: new naver.maps.Size(10, 10),
                    pixelOffset: new naver.maps.Point(10, -10),
                });

                markers.push(otherMarkers);
                infowindows.push(infowindow);
            }

            naver.maps.Event.addListener(map, "idle", () => {
                updateMarkers(map, markers);
            });

            const updateMarkers = (
                isMap,
                isMarkers
            ) => {
                const mapBounds = isMap.getBounds();
                let marker;
                let position;

                for (let i = 0; i < isMarkers.length; i += 1) {
                    marker = isMarkers[i];
                    position = marker.getPosition();

                    if (mapBounds.hasLatLng(position)) {
                        showMarker(isMap, marker);
                    } else {
                        hideMarker(marker);
                    }
                }
            };

            const showMarker = (isMap, marker) => {
                marker.setMap(isMap);
            };

            const hideMarker = (marker) => {
                marker.setMap(null);
            };

            const getClickHandler = (seq) => {
                return () => {
                    const marker = markers[seq];
                    const infoWindow = infowindows[seq];

                    if (infoWindow.getMap()) {
                        infoWindow.close();
                    } else {
                        infoWindow.open(map, marker);
                    }
                };
            };

            for (let i = 0; i < markers.length; i += 1) {
                naver.maps.Event.addListener(markers[i], "click", getClickHandler(i));
            }

            //오른쪽 클릭 메뉴 기능 추가 (테스트용 코드)
             
            const markerList = [];
            const menuLayer = $('<div style="position:absolute;z-index:10000;background-color:#fff;border:solid 1px #333;padding:10px;display:none;"></div>');
            map.getPanes().floatPane.appendChild(menuLayer[0]);
            
            /*
            naver.maps.Event.addListener(map, 'click', function(e) {
                const marker = new naver.maps.Marker({
                position: e.coord,
                map: map
                });
                markerList.push(marker);
            });
           */
            naver.maps.Event.addListener(map, 'keydown', function(e) {
                const keyboardEvent = e.keyboardEvent,
                keyCode = keyboardEvent.keyCode || keyboardEvent.which;
                const ESC = 27;

                if (keyCode === ESC) {
                keyboardEvent.preventDefault();
                for (let i = 0; i < markerList.length; i++) {
                    markerList[i].setMap(null);
                }
                markerList.length = 0;
                menuLayer.hide();
                }
            });

            naver.maps.Event.addListener(map, 'mousedown', function() {
                menuLayer.hide();
            });
            
            naver.maps.Event.addListener(map, 'rightclick', function(e) {
                const coordHtml =
                'Coord: ' + e.coord.toString() + '<br />' +
                'Point: ' + e.point + '<br />' +
                'Offset: ' + e.offset;

                menuLayer.show().css({
                left: e.offset.x,
                top: e.offset.y
                }).html(coordHtml);
            });
        
        },

    }
}
</script>
